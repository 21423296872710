import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { RecruitmentService } from './recruitment.service';
import { Subject, BehaviorSubject, Observable, switchMap, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { CreateEmail } from '../model/email.interface';
import { PerformActionApplication, IndividualAction } from '../model/application-selection.interface';
import { TilTidExportedInfo, TilTidExport } from '../model/til-tid.interface';
import { CreateSms } from '../model/sms.interface';
import { environment } from '../../environments/environment';
import { SetupService } from './setup.service';
import { RefAppExportedInfo } from '../model/ref-app.interface';
import { HiringStatus } from '../classes/hiring-status.class';
import { CandidateApplication } from '../classes/application.class';
import { ENTERPRISE_ROLES } from '../resources/roles';
import { UserService } from './user.service';
import { AlvaExportedInfo } from '../model/alva-labs.interface';
import { AgreementService } from './agreement.service';

@Injectable({
  providedIn: 'root'
})
export class PerformActionService {

  private _performActionApplications: BehaviorSubject<PerformActionApplication[]> = new BehaviorSubject<PerformActionApplication[]>([]);
  private _markIndividualAction: BehaviorSubject<IndividualAction[]> = new BehaviorSubject<IndividualAction[]>([]);
  private _batchRequestSent: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private recruitmentService: RecruitmentService,
    private userService: UserService,
    private setupService: SetupService,
    private agreementService: AgreementService

  ) { }

  get performActionApplications$(): Observable<PerformActionApplication[]> {
    return this._performActionApplications.asObservable();
  }

  get markIndividualAction$(): Observable<IndividualAction[]> {
    return this._markIndividualAction.asObservable();
  }

  get batchRequestSent$(): Observable<boolean> {
    return this._batchRequestSent.asObservable();
  }

  batchUpdateApplicationStatus({id}: HiringStatus, hiringStatuses: HiringStatus[]): Observable<string> {
    const payload = {
      applicationHiringStatus: id,
      applications: this.getSelectedApplicationIds()
    };

    return this.http.patch<string>(`${environment.applications}/application_hiring_status/batch`, payload)
      .pipe(
        tap(() => {
          const statusIds = hiringStatuses.map(hStatus => hStatus.id);
          this.recruitmentService.setChangedStatuses(statusIds);

          this.markMultipleApplicationsAsActionPerformed(id);
        })
      );
  }

  batchAskForCv(): Observable<string> {
    const applications = this.getSelectedApplicationIds();

    return this.http
      .post<string>(`${environment.cv}s/batch`, {applications})
      .pipe(
        finalize(() => {
          this._batchRequestSent.next(true);

          this.markMultipleApplicationsAsActionPerformed();
        })
      );
  }

  batchHideUnhideApplications(hide: boolean): Observable<Object> {
    const applicationIds = this.getSelectedApplicationIds();
    const endpoint = hide ? `${environment.applications}/hide` : `${environment.applications}/unhide`;
    return this.http.post(endpoint, { applicationIds })
    .pipe(
      finalize(() => {
        // this._batchRequestSent.next(true);

        this.markMultipleApplicationsAsActionPerformed();
      })
    );
  }

  askForCv({guid, id}: CandidateApplication): Observable<CandidateApplication> {
    return this.http.post<string>(`${environment.applications}/${guid}/application_ask_for_cv_requests`, {})
      .pipe(
        switchMap(() => this.recruitmentService.getApplication(id)),
        tap((application: CandidateApplication) => {
          this.recruitmentService.setRefreshedApplication(application);
        })
      );
  }

  getAskForCvEmailPreview(isTrigger: boolean, applicationId?: number): Observable<string> {
    let params = new HttpParams();
    if (isTrigger) {
      params = params.append('isTrigger', true);
      const isEnterpriseUser = ENTERPRISE_ROLES.includes(this.userService.role);
      if (isEnterpriseUser) {
        params = params.append('companyId', this.setupService.currentCompany.id);
      }
    } else if (applicationId) {
      params = params.append('application', applicationId);
    } else {
      const applications = this.getSelectedApplicationIds();
      if (applications?.length) {
        params = params.append('application', applications.toString());
      }
      params = params.append('isBatch', true);
    }

    return this.http.get(`${environment.emailPreview}/ask_for_cv`, { params, responseType: 'text' });
  }

  batchSendSms(smsApplicationMessage: CreateSms): Observable<string> {
    const applications = this.getSelectedApplicationIds();

    return this.http
      .post<string>(`${environment.sms}/batch`, {smsApplicationMessage, applications})
      .pipe(
        finalize(() => {
          this._batchRequestSent.next(true);

          this.markMultipleApplicationsAsActionPerformed();
        })
      );
  }

  sendSms(applicationId: number, createSms: CreateSms): Observable<CandidateApplication> {
    return this.http
      .post(
        `${environment.applications}/${applicationId}/sms`,
        createSms
      )
      .pipe(
        switchMap(() => this.recruitmentService.getApplication(applicationId)
          .pipe(
            tap((application: CandidateApplication) => this.recruitmentService.setRefreshedApplication(application))
          ))
      );
  }

  batchSendEmail(email: CreateEmail): Observable<string> {
    const endpoint = !(email.agreementForms && email.agreementForms.length) ?
    `${environment.applicationEmails}/batch` : `${environment.applicationEmails}/with_agreements/batch`;

    const applications = this.getSelectedApplicationIds();

    return this.http
      .post<string>(
        endpoint, {applications, ...email}
      )
      .pipe(
        finalize(() => {
          this._batchRequestSent.next(true);

          this.markMultipleApplicationsAsActionPerformed();
        })
      );
  }

  sendEmail(email: CreateEmail, applicationId: number): Observable<CandidateApplication> {
    const endpoint = !(email.agreementForms && email.agreementForms.length) ?
    environment.applicationEmails : `${environment.applicationEmails}/with_agreements`;
    return this.http
      .post(endpoint, email)
      .pipe(
        switchMap(() => this.recruitmentService.getApplication(applicationId)
          .pipe(
            tap((application: CandidateApplication) => {
              this.recruitmentService.setRefreshedApplication(application);
              if (email.agreementForms && email.agreementForms.length) {
                this.agreementService.refreshAgreementList();
              }
            })
          ))
      );
  }

  getEmailPreview(email: CreateEmail, isTrigger: boolean, applicationId?: number): Observable<any> {
    let params = new HttpParams();
    if (email.greeting) {
      params = params.append('greeting', email.greeting);
    }
    params = params.append('content', email.content);
    if (isTrigger) {
      params = params.append('isTrigger', true);
      if (email.useUrl) {
        params = params.append('useUrl', true);
      }
      const isEnterpriseUser = ENTERPRISE_ROLES.includes(this.userService.role);
      if (isEnterpriseUser) {
        params = params.append('companyId', this.setupService.currentCompany.id);
      }
    } else if (applicationId) {
      params = params.append('application', applicationId);
    } else {
      const applications = this.getSelectedApplicationIds();
      if (applications?.length) {
        params = params.append('application', applications.toString());
      }
      params = params.append('isBatch', true);
    }

    return this.http.get(`${environment.emailPreview}/communication_email`, { params, responseType: 'text' });
  }

  batchExportToTilTid(exportedTilTidEmployee: TilTidExport): Observable<HttpResponse<TilTidExportedInfo>> {
    const applications = this.getSelectedApplicationIds();

    return this.http
      .post<TilTidExportedInfo>(
        `${environment.exportedTilTidEmployees}/batch`,
        {applications, exportedTilTidEmployee, company: this.setupService.companyId},
        {observe: 'response'}
      )
      .pipe(
        finalize(() => {
          this._batchRequestSent.next(true);

          this.markMultipleApplicationsAsActionPerformed();
        })
      );
  }

  exportToTilTid(tilTidExport: TilTidExport): Observable<any> {
    return this.http.post(`${environment.exportedTilTidEmployees}`, tilTidExport);
  }

  batchExportToRefApp(): Observable<HttpResponse<RefAppExportedInfo>> {
    const applications = this.getSelectedApplicationIds();

    return this.http
      .post<RefAppExportedInfo>(
        `${environment.refAppReferenceCheckBatch}`,
        {applications, company: this.setupService.companyId},
        {observe: 'response'}
      )
      .pipe(
        finalize(() => {
          this._batchRequestSent.next(true);

          this.markMultipleApplicationsAsActionPerformed();
        })
      );
  }

  selectApplication(id: number, status: number, isAnonymous: boolean, isHidden: boolean, isCandidateDataDelated: boolean, doesNeedDetaDelatedCheck: boolean, doesNeedAnonymousCheck?: boolean): void {
    const applications = [ ...this._performActionApplications.getValue() ];
    const index = applications.findIndex(app => app.appId === id);

    const newApp: PerformActionApplication = {
      actionPerformed: false,
      isSelected: true,
      appId: id,
      status,
      isAnonymous,
      doesNeedAnonymousCheck,
      isHidden,
      isCandidateDataDelated,
      doesNeedDetaDelatedCheck
    };

    if (index >= 0) {
      applications[index] = newApp;
    } else {
      applications.push(newApp);
    }

    this._performActionApplications.next(applications);
  }

  deselectApplication(id: number): void {
    const applications = this._performActionApplications
      .getValue()
      .filter(app => app.appId !== id);

    this._performActionApplications.next(applications);
  }

  deselectApplicationsByStatus(status: number, isHidden: boolean): void {
    const apps = this._performActionApplications
      .getValue()
      .filter(app => app.status !== status || app.isHidden !== isHidden);

    this._performActionApplications.next(apps);
  }

  clearApplicationsToPerformAction(): void {
    this._performActionApplications.next([]);
  }

  getSelectedApplications(): PerformActionApplication[] {
    return this._performActionApplications
      .getValue()
      .filter(app => app.isSelected);
  }

  getSelectedApplicationIds(): number[] {
    return this._performActionApplications
      .getValue()
      .filter(app => app.isSelected)
      .map(app => app.appId);
  }

  markApplicationAsActionPerformed(appId: number, status: number): void {
    const performedActionApplication: PerformActionApplication = {
      actionPerformed: true,
      isSelected: false,
      appId,
      status
    };

    this._performActionApplications.next([performedActionApplication]);
  }

  markMultipleApplicationsAsActionPerformed(statusId?: number): void {
    let performActionApplications = [...this._performActionApplications.getValue() ];

    performActionApplications = performActionApplications
      .filter((app: PerformActionApplication) => app.isSelected)
      .map((app: PerformActionApplication) => {
        app.actionPerformed = true;
        app.isSelected = false;

        if (statusId) {
          app.status = statusId;
        }

        return app;
      });

    this._performActionApplications.next(performActionApplications);
  }

  markIndividualAction(individualAction: IndividualAction): void {
    const applications = [...this._markIndividualAction.getValue() ];
    const index = applications.findIndex(app => app.id === individualAction.id);

    if (index >= 0) {
      applications[index] = {...applications[index], ...individualAction};
    } else {
      applications.push(individualAction);
    }

    this._markIndividualAction.next(applications);
  }

  clearIndividualAction(): void {
    this._markIndividualAction.next([]);
  }

  batchExportToAlva(alvaPayload: any, applications: number[]): Observable<HttpResponse<AlvaExportedInfo>> {

    return this.http
      .post<AlvaExportedInfo>(
        `${environment.alvaBatch}`,
        {applications, ...alvaPayload, company: this.setupService.companyId},
        {observe: 'response'}
      )
      .pipe(
        finalize(() => {
          this._batchRequestSent.next(true);

          this.markMultipleApplicationsAsActionPerformed();
        })
      );
  }

  anonymousCheck(appIds: number[]): Observable<boolean> {
    if (appIds.length === 0) {
      return of(false);
    }
    let params = new HttpParams();
    if (appIds) {
      params = params.append('applicationIds', appIds.toString());
    }

    // eslint-disable-next-line max-len
    return this.http.get<boolean>(`${environment.company}/${this.setupService.companyGuid}/applications/are_unhandled_and_anonymous`, { params });
  }

  deletedCheck(appIds: number[]): Observable<boolean> {
    if (appIds.length === 0) {
      return of(false);
    }
    let params = new HttpParams();
    if (appIds) {
      params = params.append('applicationIds', appIds.toString());
    }

    // eslint-disable-next-line max-len
    return this.http.get<boolean>(environment.idCandidateDataDeleted, { params });
  }
}
